import React from 'react';
import { Text, Link } from '@movember/mo-gel';
import {
  StyledModal,
  Content,
  Heading,
  Paragraph,
  EstimateContainer,
  Estimate,
  NumberContainer,
  StartButton,
  TopLogoContainer,
  Logo,
  BottomLogoContainer
} from './welcomeModal.styled';
import {
  EPIC26_PLUS_QUESTIONNAIRE,
  EPIC26_QUESTIONNAIRE,
  MO_TC_26,
  PCORANZ_PARTICIPANT_BASELINE,
  PCORANZ_PARTICIPANT_FOLLOWUP
} from '../../../constants';
import TrueNorthLogo from '../../../assets/Logos/TrueNorth.svg';
import PCORLogo from '../../../assets/Logos/PCOR.svg';
import ACTLogo from '../../../assets/Logos/ACT.svg';
import NSWLogo from '../../../assets/Logos/NSW.svg';
import NTLogo from '../../../assets/Logos/NT.svg';
import NZLogo from '../../../assets/Logos/NZ.svg';
import QLDLogo from '../../../assets/Logos/QLD.svg';
import SALogo from '../../../assets/Logos/SA.svg';
import TASLogo from '../../../assets/Logos/TAS.svg';
import VICLogo from '../../../assets/Logos/VIC.svg';

export interface WelcomeModalProps {
  isOpen: boolean;
  numberOfQuestions: number;
  dismiss: () => void;
  questionnaire: string;
  jurisdiction: string;
}

const jurisdictionLogoData: { [key: string]: any } = {
  ACT: {
    alt: 'ACT Health Logo',
    logo: ACTLogo
  },
  NSW: {
    alt: 'Cancer Institute NSW Logo',
    logo: NSWLogo
  },
  NZ: {
    alt: 'Centre for Health Outcome Measure New Zealand Logo',
    logo: NZLogo
  },
  NT: {
    alt: 'Northern Territory Department of Health Logo',
    logo: NTLogo
  },
  SA: {
    alt: 'South Australia Prostate Cancer Clinical Outcomes Collaborative Logo',
    logo: SALogo
  },
  QLD: {
    alt: 'Australian Prostate Cancer Research Centre Queensland Logo',
    logo: QLDLogo
  },
  TAS: {
    alt: 'University of Tasmania - Menzies Institute for Medical Research Logo',
    logo: TASLogo
  },
  VIC: {
    alt: 'Monash University Logo',
    logo: VICLogo
  }
};

// The estimated amount of minutes required to complete a single question
const estimateMultiplier = 0.15;

/**
 * Gets an custom welcome message for the given questionnaire.
 * @param questionnaire The questionnaire being completed.
 * @returns A custom welcome message.
 */
const getWelcomeMessage = (questionnaire: string): string => {
  if (questionnaire === EPIC26_PLUS_QUESTIONNAIRE || questionnaire === EPIC26_QUESTIONNAIRE) {
    return 'This questionnaire will ask about your quality of life, including day-to-day issues with urinary, bowel, sexual and hormonal changes. There are no right or wrong answers.';
  }
  if (questionnaire === MO_TC_26) {
    return 'This questionnaire will ask about your quality of life. There are no right or wrong answers.';
  }
  if (questionnaire === PCORANZ_PARTICIPANT_BASELINE) {
    return 'This survey will ask about your quality of life after diagnosis of prostate cancer. There are no right or wrong answers.';
  }
  if (questionnaire === PCORANZ_PARTICIPANT_FOLLOWUP) {
    return 'This survey will ask about your quality of life after treatment for prostate cancer. There are no right or wrong answers.';
  }
  throw new Error(`Unable to get welcome message for questionnaire: ${questionnaire}`);
};

export default function WelcomeModal({
  isOpen,
  numberOfQuestions,
  dismiss,
  questionnaire,
  jurisdiction
}: WelcomeModalProps) {
  const welcomeMessage = getWelcomeMessage(questionnaire);

  const renderJurisdictionLogos = (state: string) => {
    const logoData = jurisdictionLogoData[state];

    return (
      <>
        {logoData && <Logo src={logoData.logo} alt={logoData.alt} />}
        <Logo src={PCORLogo} alt="Prostate Cancer Outcomes Registory Australia and New Zealand Logo" />
      </>
    );
  };

  return (
    <StyledModal isOpen={isOpen} onToggle={dismiss}>
      <Content id="content">
        <TopLogoContainer>{renderJurisdictionLogos(jurisdiction)}</TopLogoContainer>
        <Heading>
          Thank you for agreeing to complete the Quality of Life Survey. We appreciate your time and honesty.
        </Heading>
        <Paragraph>{welcomeMessage}</Paragraph>
        <EstimateContainer>
          <Estimate>
            <NumberContainer>
              <Text tag="span">10</Text>
              <Text tag="span">min</Text>
            </NumberContainer>
            <Text tag="span">estimated time to complete</Text>
          </Estimate>
          <Estimate>
            <NumberContainer>
              <Text tag="span">{numberOfQuestions.toString()}</Text>
            </NumberContainer>
            <Text tag="span">Questions</Text>
          </Estimate>
        </EstimateContainer>
        <Paragraph>
          <strong>Please try to answer every question as accurately as you can.</strong>
        </Paragraph>
        <StartButton primary onClick={dismiss}>
          Start Survey
        </StartButton>
        <BottomLogoContainer>
          <img src={TrueNorthLogo} alt="Movember Funded Logo" />
        </BottomLogoContainer>
      </Content>
    </StyledModal>
  );
}
