import styled from 'styled-components';
import { Text, Modal, Link } from '@movember/mo-gel';
import { IStyledProps } from '../../../models/StyledProps';

export const StyledModal = styled(Modal)`
  z-index: 99;
  text-align: center;
  overflow: auto;
`;

export const StyledLink = styled(Link)`
  margin-top: 2rem;
  cursor: pointer;

  & span {
    @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.md}) {
      font-size: 1.15rem;
      line-height: 1.15rem;
    }

    @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.lg}) {
      font-size: 1.35rem;
      line-height: 1.35rem;
    }
  }
`;

export const StyledText = styled(Text)`
  font-size: 1rem;
  padding-top: 0.75rem;

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.md}) {
    font-size: 1.15rem;
    line-height: 1.6rem;
  }

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.lg}) {
    font-size: 1.35rem;
    line-height: 1.8rem;
  }
`;
