import styled from 'styled-components';
import { Modal, Text } from '@movember/mo-gel';
import { IStyledProps } from '../../../models/StyledProps';

export const StyledModal = styled(Modal)`
  z-index: 99;
  text-align: center;
  overflow: auto;
`;

export const StyledText = styled(Text)`
  text-align: center;
  width: 100%;
  margin-bottom: 1.5rem;

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.md}) {
    font-size: 1.15rem;
    line-height: 1.6rem;
  }

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.lg}) {
    font-size: 1.35rem;
    line-height: 1.8rem;
  }
`;
