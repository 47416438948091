// Theme
import { DefaultTheme } from 'styled-components';
import { TrueNorthTesticularCancer, TrueNorthDark, GenieTheme } from '../theme';

// logo
import trueNorthLogo from '../assets/tn-primary.svg';
import genieLogo from '../assets/genie-logo.svg';

// Models
import { Brand } from '../models/brand';

const brandKeys = ['tn', 'tntc', 'genie'];

const logoMapper: { [key: string]: string } = {
  tn: trueNorthLogo,
  tntc: trueNorthLogo,
  genie: genieLogo
};

const themeMapper: { [key: string]: DefaultTheme } = {
  tn: TrueNorthDark,
  tntc: TrueNorthTesticularCancer,
  genie: GenieTheme
};

const BrandService = {
  defaultBrandName: 'tn',
  getBrand: (brandName: string | null): Brand => {
    const brand = brandName || BrandService.defaultBrandName;

    return {
      name: brand,
      logo: logoMapper[brand as string],
      theme: themeMapper[brand as string]
    };
  },
  isBrandName: (brandName: any): boolean => {
    if (brandKeys.indexOf(brandName) !== -1) {
      return true;
    }
    return false;
  }
};

export default BrandService;
