import React, { Component, useState } from 'react';
import * as axios from 'axios';
import { withRouter, RouteComponentProps } from 'react-router';
import { compressSync, strToU8, strFromU8 } from 'fflate';
import { GhostLink } from '@movember/mo-gel';
import {
  StyledHeaderText,
  StyledText,
  StyledPromptContainer,
  StyledSubmitButton,
  StyledContainer,
  StyledGhostLinkText
} from './confirmSubmission.styled';
import { ThankYouModal } from '../modals/thankYouModal/thankYouModal';
import { SubmitErrorModal } from '../modals/submitErrorModal/submitErrorModal';
import { HEADER_TOKEN_KEY, COMPLETE_STATUS, SKIPPED } from '../../constants';
import { IQuestionnaireResponse } from '../../models/questionnaireResponse';
import { IQuestion } from '../../models/questionaire';

interface ConfirmSubmissionProps extends RouteComponentProps<any> {
  selectedOptions: IQuestionnaireResponse;
  questions: IQuestion[];
  questionnaire: string;
  back: any;
}

function ConfirmSubmission({ selectedOptions, questions, questionnaire, back }: ConfirmSubmissionProps) {
  const [isOpened, setOpen] = useState<boolean>(false);
  const [isFailed, setFail] = useState<boolean>(false);

  const showSubmitErrorModal = () => setFail(true);
  const showThankYouModal = () => setOpen(true);

  const submitResponse = async () => {
    const questionsSkipped =
      (selectedOptions && selectedOptions.item.filter((item) => item.answer[0].valueCoding.code === SKIPPED).length) ||
      0;
    const questionsAnswered = (selectedOptions && selectedOptions.item.length - questionsSkipped) || 0;

    /// compress the body to avoid aws waf 8KB max body rule
    const buffer = strToU8(JSON.stringify({ answers: { ...selectedOptions, status: COMPLETE_STATUS } })); // stringify and convet to U8 typed array
    const compressedAnswers = window.btoa(strFromU8(compressSync(buffer), true)); // compress, convert to latin1 string and base64 encode
    const data = { compressedAnswers };

    return new Promise((resolve, reject) =>
      axios.default
        .put(`${process.env.REACT_APP_API_ENDPOINT}/answers`, data, {
          headers: { [HEADER_TOKEN_KEY]: sessionStorage.getItem(HEADER_TOKEN_KEY) }
        })
        .then((response) => {
          if (response.status === 200) {
            return resolve(showThankYouModal());
          }
          return showSubmitErrorModal();
        })
        .catch(() => showSubmitErrorModal())
    ).catch((error) => error);
  };

  return (
    <>
      <StyledContainer>
        <GhostLink onClick={back} icon="up">
          <StyledGhostLinkText>Back to questions</StyledGhostLinkText>
        </GhostLink>
        <StyledPromptContainer>
          <StyledHeaderText>Well done!</StyledHeaderText>
          <StyledText>You’ve reached the end of the Quality of Life survey.</StyledText>
          <StyledText>You can submit your responses now.</StyledText>
        </StyledPromptContainer>
        <StyledSubmitButton primary onClick={submitResponse}>
          Submit responses
        </StyledSubmitButton>
      </StyledContainer>
      <ThankYouModal isOpen={isOpened} />
      <SubmitErrorModal isFailed={isFailed} />
    </>
  );
}

export default withRouter(ConfirmSubmission);
