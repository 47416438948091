import React from 'react';
import BrandService from '../../services/brandService';
import { StyledGenieLogo } from './logo.styled';

export const Logo = (props: any) => {
  const brand = BrandService.getBrand(sessionStorage.getItem('brand'));

  const { theme, lighttheme }: { theme: any; lighttheme: boolean } = props;

  switch (brand.name) {
    case 'genie':
      return <StyledGenieLogo theme={theme} lighttheme={lighttheme} />;

    default:
      return <StyledGenieLogo theme={theme} lighttheme={lighttheme} />;
  }
};
