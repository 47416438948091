import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter as Router } from 'react-router-dom';
import * as axios from 'axios';
import App from './App';
import { HEADER_TOKEN_KEY, BRAND_NAME } from './constants';

async function getQuestions(token: string): Promise<any> {
  if (token) {
    sessionStorage.removeItem(HEADER_TOKEN_KEY);
  }
  return axios.default
    .post(`${process.env.REACT_APP_API_ENDPOINT}/questions`, '', {
      headers: {
        [HEADER_TOKEN_KEY]: token
      }
    })
    .then((response) => {
      // TO-DO: Might be able to remove this once we have the get patient answers endpoint in
      // If the token is valid we store it in sessionStorage
      sessionStorage.setItem(HEADER_TOKEN_KEY, token);
      return response.data;
    });
}

async function getAnswers(token: string): Promise<any> {
  return axios.default
    .post(`${process.env.REACT_APP_API_ENDPOINT}/answers`, '', {
      headers: {
        [HEADER_TOKEN_KEY]: token
      }
    })
    .then((response) => {
      // If the token is valid we store it in sessionStorage
      sessionStorage.setItem(HEADER_TOKEN_KEY, token);
      if (response.data.questionnaire) {
        sessionStorage.setItem('questionnaire', response.data.questionnaire);
      }
      return response.data;
    });
}

const apiCalls = async (brandName: string, token: string | null): Promise<any> => {
  if (token) {
    const questions = await getQuestions(token);
    const answers = await getAnswers(token);
    return new Promise((resolve) => resolve([questions.questionnaireType, questions.questions, answers.data]));
  }
  return new Promise((reject) => reject('No token was supplied'));
};

ReactDOM.render(
  <Router>
    <App apiCalls={apiCalls} />
  </Router>,
  document.getElementById('root')
);
