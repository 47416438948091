import styled from 'styled-components';
import { Button, Modal } from '@movember/mo-gel';
import { IStyledProps } from '../../../models/StyledProps';

export const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  z-index: 99;
  overflow: auto;

  > div:first-child {
    max-width: 100%;
    align-items: center;
    margin: auto;
    /* max-height: 100%; */

    @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.md}) {
      max-width: 85%;
      width: 85%;
    }

    @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.lg}) {
      max-width: 50%;
      width: 50%;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${(props: IStyledProps) => props.theme.colors.white};

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.md}) {
    max-width: 600px;
  }
`;

export const Heading = styled.h1`
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.2rem;
  text-align: center;
  margin: 0 0 1rem 0;

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.sm}) {
    margin: 0 0 1.5rem 0;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1.68rem;
  }

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.md}) {
    width: 480px;
    font-size: 1.619rem;
    line-height: 2rem;
  }
`;

export const Paragraph = styled.p`
  font-size: 0.6rem;
  line-height: 1rem;
  text-align: center;
  margin: 0 0 1rem 0;

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.sm}) {
    margin: 0 0 2rem 0;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.md}) {
    max-width: 528px;
    font-size: 1.125rem;
    line-height: 1.689rem;
  }
`;

export const EstimateContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const Estimate = styled.div`
  width: 152px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  padding: 0 1.5rem;
  margin-bottom: 1rem;

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.sm}) {
    margin-bottom: 1.5rem;
    height: 127px;
  }

  &:nth-of-type(1) {
    border-right: 1px solid ${(props: IStyledProps) => props.theme.colors.brandThemeDark500};
  }

  & span {
    font-size: 0.6rem;
    line-height: 1rem;
    height: 3.375rem;
    display: flex;
    align-items: flex-end;

    @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.sm}) {
      font-size: 0.94rem;
      line-height: 1.125rem;
    }
  }

  & > span {
    display: flex;
    align-items: flex-start;
    margin-top: 0.625rem;
  }
`;

export const NumberContainer = styled.div`
  display: flex;
  align-items: flex-end;
  height: 54px;

  & span:nth-child(1) {
    font-size: 2.4rem;
    font-weight: bold;
    line-height: 2.4rem;
    letter-spacing: -0.75px;
    color: ${(props: IStyledProps) => props.theme.colors.actionPrimary300};
    margin-right: 3px;

    @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.sm}) {
      font-size: 2.8rem;
      line-height: 2.8rem;
    }
  }

  & span:nth-child(2) {
    font-size: 1.125rem;
    line-height: 1.687rem;
    color: ${(props: IStyledProps) => props.theme.colors.actionPrimary300};
  }
`;

export const StartButton = styled(Button)`
  font-size: 0.8rem;
  font-weight: bold;
  line-height: 1.3rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  width: auto;
  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.sm}) {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
`;

export const TopLogoContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding-top: 1rem;
  padding-bottom: 3rem;
  max-width: 100%;
  width: 100%;
`;

export const Logo = styled.img`
  max-width: 40%;
`;

export const BottomLogoContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 5rem;

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.sm}) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.lg}) {
    padding-top: 2rem;
  }
`;
