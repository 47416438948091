import React from 'react';
import { ReactComponent as SuccessIcon } from '@movember/mo-gel/assets/icons/icon_success.svg';
import { StyledModal, StyledHeading, StyledText } from './savedModal.styled';

type SavedModalProps = {
  isOpen: boolean;
};

export default function SavedModal({ isOpen }: SavedModalProps) {
  return (
    <StyledModal isOpen={isOpen}>
      <SuccessIcon />
      <StyledHeading level="h2">Your responses have been saved.</StyledHeading>
      <StyledText>Use the link provided in the email to complete your survey before the due date.</StyledText>
    </StyledModal>
  );
}
