import * as React from 'react';
import { Heading } from '@movember/mo-gel';
import {
  StyledModal,
  StyledModalContent,
  StyledModalText,
  StyledButton,
  StyledButtonContainer
} from './questionSkipModal.styled';

export interface QuestionSkipModalProps {
  isOpen: boolean;
  onHideModal: () => void;
  onSkipQuestion: () => void;
}

export default function QuestionSkipModal({ isOpen, onHideModal, onSkipQuestion }: QuestionSkipModalProps) {
  const handleSkipQuestion = () => {
    onSkipQuestion();
    onHideModal();
  };

  return (
    <StyledModal isOpen={isOpen}>
      <StyledModalContent>
        <Heading level="h2">Skip Question?</Heading>
        <StyledModalText>
          Remember - the more questions you answer, the better we can understand your prostate cancer experience.
        </StyledModalText>
        <StyledButtonContainer>
          <StyledButton tertiary onClick={handleSkipQuestion}>
            Yes, skip question
          </StyledButton>
          <StyledButton primary onClick={onHideModal}>
            No, don&apos;t skip question
          </StyledButton>
        </StyledButtonContainer>
      </StyledModalContent>
    </StyledModal>
  );
}
