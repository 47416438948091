import React, { useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router';
import { IQuestionContainer } from './model';
import { StyledQuestionContainer, StyledCardContainer } from '../../questionnaire.styled';
import { QuestionCard } from '../../../questionCard/questionCard';
import {
  getSelectedOptions,
  handleDeselect,
  handleNext,
  handlePrevious,
  handleSelection,
  handleShowSkipModal
} from '../../helper';
import { PARENT_YES, SKIPPED } from '../../../../constants';
import { TrueNorthDark } from '../../../../theme';

export const QuestionContainer: React.FC<IQuestionContainer> = ({
  index,
  currentIndex,
  isResponseDisabled,
  questions,
  jurisdiction,
  selectedOptions,
  setCurrentIndex,
  setSelectedOptions,
  setIsResponseDisabled,
  setShowSkipModal,
  setSkippingQuestion
}) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const childRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let previousY = 0;

    const handleScroll = () => {
      // only do this on tablet+desktop, if mobile we use native scrolling
      if (window.innerWidth < parseInt(TrueNorthDark.breakpoints.sm, 10)) {
        return;
      }
      if (!parentRef.current || !childRef.current) {
        return;
      }
      const newY = parentRef.current?.scrollTop;
      const isScrollingDown = newY > previousY;
      const isScrollingUp = newY < previousY;
      // this is the actual div that we want to scroll
      const questionCardDiv = childRef.current?.querySelector(':first-child > div');
      if (!questionCardDiv) {
        return;
      }
      // need to figure out how much to scroll the question card by so that we can actually reach the bottom
      let scrollAmount;
      if (window.innerWidth < parseInt(TrueNorthDark.breakpoints.lg, 10)) {
        scrollAmount = (questionCardDiv.scrollHeight / parentRef.current.scrollHeight) * 20 + 10;
      } else {
        scrollAmount = (questionCardDiv.scrollHeight / parentRef.current.scrollHeight) * 20 + 30;
      }

      if (isScrollingDown) {
        questionCardDiv.scrollTop += scrollAmount;
      } else if (isScrollingUp) {
        questionCardDiv.scrollTop -= scrollAmount;
      }
      previousY = newY;
    };

    parentRef.current?.addEventListener('scroll', handleScroll);
    parentRef.current?.addEventListener('touchmove', handleScroll);

    return () => {
      parentRef.current?.removeEventListener('scroll', handleScroll);
      parentRef.current?.removeEventListener('touchmove', handleScroll);
    };
  }, [parentRef, childRef]);

  const items = selectedOptions.item;
  const history = useHistory();
  const child: any = useParams();
  const question = questions[index];

  // get parent answer object
  const parent =
    question.enableWhen &&
    items.find((i) => +i.linkId === +question.enableWhen![0].question) &&
    items.find((i) => +i.linkId === +question.enableWhen![0].question)!.answer &&
    items.find((i) => +i.linkId === +question.enableWhen![0].question)!;

  // if parent's answer is not "Yes"
  // return true, else false
  const parentAnswer = (parent && parent.answer[0].valueCoding.code !== PARENT_YES) || false;

  // get group question saved answers
  let groupAnswers: any = [];
  if (question && question.repeats) {
    const { linkId, groupId } = question;
    const getGroupId = selectedOptions.item.filter((item) => item.linkId === linkId).map((item) => item.groupId);
    const selectedAnswers = selectedOptions.item
      .filter((item) => getGroupId[0] && item.groupId === getGroupId[0])
      .map((item) => item)
      .flat();
    groupAnswers = { groupId, answers: selectedAnswers };
  }

  return (
    <StyledQuestionContainer key={index} isDisplay={!parentAnswer} ref={parentRef}>
      <StyledCardContainer ref={childRef}>
        <QuestionCard
          index={index}
          maxQuestions={questions.length}
          question={question}
          isGroupQuestions={question.repeats}
          groupQuestionAnswers={question.repeats && groupAnswers.answers.length > 0 && groupAnswers}
          showNext={getSelectedOptions(selectedOptions, index.toString())[0] !== SKIPPED}
          showPrevious={currentIndex > 1}
          selectedOptions={getSelectedOptions(
            selectedOptions,
            (child.child && (parseInt(question.linkId.toString(), 10) + 1).toString()) || question.linkId
          )}
          isResponseDisabled={isResponseDisabled}
          onSelect={(selectedIndexes: number[]) => {
            handleSelection(
              history,
              questions,
              currentIndex,
              setCurrentIndex,
              selectedOptions,
              setSelectedOptions,
              setIsResponseDisabled,
              question,
              selectedIndexes
            );
          }}
          onDeselect={() =>
            handleDeselect(history, questions, currentIndex, selectedOptions, setSelectedOptions, question)
          }
          onSkip={() => handleShowSkipModal(setIsResponseDisabled, setShowSkipModal, setSkippingQuestion, question)}
          onPrevious={() =>
            handlePrevious(history, selectedOptions, currentIndex, setCurrentIndex, setIsResponseDisabled)
          }
          onNext={(response?) => {
            handleNext(
              history,
              questions,
              selectedOptions,
              setSelectedOptions,
              currentIndex,
              setCurrentIndex,
              setIsResponseDisabled,
              question,
              response
            );
          }}
        />
      </StyledCardContainer>
    </StyledQuestionContainer>
  );
};
