import React from 'react';
import { Checkbox } from '@movember/mo-gel';

interface QuestionCheckBoxProps {
  name: string;
  isChecked: boolean;
  label: string;
  onChange: (i: number) => void;
}

export function QuestionCheckBox({ name, isChecked, onChange, label }: QuestionCheckBoxProps) {
  return (
    <Checkbox name={name} checked={isChecked} color="white" onChange={() => onChange(Number(name))}>
      {label}
    </Checkbox>
  );
}
