import { DefaultTheme } from 'styled-components';

const colors = {
  white: '#ffffff',
  black: '#000000',
  brandThemeDark900: '#22232a',
  brandThemeDark700: '#505562',
  brandThemeDark500: '#7b899d',
  brandThemeLight500: '#b5ebff',
  brandThemeLight300: '#d3f2ff',
  brandThemeLight100: '#edfaff',
  actionPrimary700: '#003a66',
  actionPrimary500: '#00579a',
  actionPrimary300: '#008fff',
  primaryShadow: 'rgba(12, 30, 44, 0.3)',
  primaryFont: '#fff',
  accentFont: '#4ba1e9',

  error500: '#bf0000',
  // new
  error300: '#e62323',

  // warning colors: orange and yellow
  warning500: '#f26800',
  // originally warning 1
  warning300: '#ff8200',

  // darkest/worse (500) to lightest/best (300)
  success500: '#76b33b',
  success300: '#8fd848'
};

const modal = {
  padding: `1rem`,
  background: {
    withLightBg: colors.white,
    withDarkBg: colors.brandThemeDark900
  },
  textColor: {
    withLightBg: colors.primaryFont,
    withDarkBg: colors.white
  },
  closeBgColor: 'rgba(255, 255, 255, 0.1)',
  closeHoverBgColor: colors.actionPrimary300
};

const breakpoints = {
  xxs: '320px',
  xs: '520px',
  sm: '768px',
  md: '1024px',
  lg: '1280px',
  xl: '1760px'
};

export const TrueNorthTesticularCancer: DefaultTheme = {
  dark: true,
  name: 'True North Testicular Cancer',
  colors,
  modal,
  breakpoints,
  containerMaxWidth: '1080px'
};
