import styled from 'styled-components';
import { Heading, Text } from '@movember/mo-gel';
import { IStyledProps } from '../../models/StyledProps';

export const StyledMessage = styled.div`
  width: 100vw;
  padding-top: 8rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: ${(props: IStyledProps) => props.theme.colors.white};
`;

export const StyledHeading = styled(Heading)`
  text-align: center;
  width: 100%;
  margin-bottom: 3rem;
`;

export const StyledText = styled(Text)`
  text-align: center;
  width: 100%;
`;
