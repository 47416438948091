import styled from 'styled-components';
import { Text, Button, Modal } from '@movember/mo-gel';
import { IStyledProps } from '../../../models/StyledProps';

export const StyledModal = styled(Modal)`
  z-index: 99;
  overflow: auto;
`;

export const StyledModalContent = styled.div`
  max-width: 343px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.md}) {
    width: 700px;
    max-width: 700px;
  }
`;

export const StyledModalText = styled(Text)`
  max-width: 343px;
  font-size: 1rem;
  margin-bottom: 2rem;
  text-align: center;

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.md}) {
    font-size: 1.15rem;
    line-height: 1.6rem;
    margin-bottom: 3rem;
    max-width: 453px;
  }
`;

export const StyledButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  button:last-child {
    margin-top: 1rem;
  }

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.md}) {
    flex-direction: row;
    justify-content: center;
    button:last-child {
      margin-left: 1rem;
      margin-top: 0;
    }
  }
`;

export const StyledButton = styled(Button)`
  font-size: 1.125rem;
  font-weight: bold;
  line-height: 1.75rem;
  max-width: 300px;
`;
