import styled from 'styled-components';
import { Modal, Button, Heading, Text } from '@movember/mo-gel';
import { IStyledProps } from '../../../models/StyledProps';

export const StyledModal = styled(Modal)`
  z-index: 99;
  text-align: center;
  overflow: auto;
`;

export const StyledHeading = styled(Heading)`
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

export const StyledText = styled(Text)`
  font-size: 16px;
  width: 80%;

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.md}) {
    font-size: 18px;
  }
`;
