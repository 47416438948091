import styled from 'styled-components';
import { Card, Text, Icon } from '@movember/mo-gel';
import { ReactComponent as Arrow } from '../../assets/arrow_forward.svg';
import { IStyledProps } from '../../models/StyledProps';

export interface IStyledQuestionCardProps extends IStyledProps {
  disabled: boolean;
}

export const StyledCard = styled(Card)`
  background: transparent;
  pointer-events: ${(props: IStyledQuestionCardProps) => (props.disabled ? 'none' : 'auto')};
  color: ${(props: IStyledProps) => props.theme.colors.white};
  border: none;
  box-shadow: none;
  width: 100%;
  min-height: 100vh;
  padding-top: 4rem;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
  justify-content: flex-start;
  display: flex;
  flex-direction: column;

  /* safari flags */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-box-direction: column;
  /*  */

  padding-right: 0;
  padding-left: 0;

  @media screen and (max-height: 600px) {
    height: 700px;
  }

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.sm}) {
    width: 535px;
    margin: 0;
    padding-top: 9rem;
  }

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.md}) {
    /* on desktop we have a useEffect for scrolling */
    overflow: hidden;
    width: 640px;
  }

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.lg}) {
    width: 700px;
    padding-bottom: 4rem;
  }

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.xl}) {
    max-width: 740px;
  }
`;

export const StyledQuestionTitle = styled(Text)`
  letter-spacing: 2px;
  text-transform: uppercase;

  & span {
    text-transform: lowercase;
  }
`;

export const StyledQuestion = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0;
  font-size: 1.15rem;
  font-weight: bold;
`;

export const StyledQuestionText = styled(Text)`
  font-size: 1.15rem;

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.md}) {
    font-size: 1.35rem;
    line-height: 1.6rem;
    width: 616px;
  }

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.lg}) {
    font-size: 1.5rem;
    line-height: 1.8rem;
  }

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.xl}) {
    font-size: 1.65rem;
    line-height: 2rem;
  }
`;

export const StyledArrow = styled(Arrow)`
  margin-top: 0.4rem;
  margin-right: 0.6rem;
  width: 2rem;

  path {
    fill: ${(props: IStyledProps) => props.theme.colors.white};
  }
`;

export const StyledButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: 'flex-start';
  align-items: center;
  color: ${(props: IStyledProps) => props.theme.colors.primaryFont};
  margin-bottom: 4rem;
`;

export const StyledGhostLinkText = styled(Text)`
  font-size: 1rem;

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.md}) {
    font-size: 1.125rem;
  }

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.lg}) {
    font-size: 1.25rem;
  }

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.xl}) {
    font-size: 1.375rem;
  }
`;

export const StyledLinkSeperator = styled.span`
  display: block;
  border-right: 2px solid ${(props: IStyledProps) => props.theme.colors.primaryFont};
  opacity: 0.25;
  height: 2rem;
  margin: 0 0.75rem;
`;

export const StyledDoneButtonContent = styled.div`
  display: flex;
`;

export const StyledTickIcon = styled(Icon)`
  margin-left: 0.5rem;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
`;

export const GroupQuestion = styled.div`
  display: flex;
  width: 100%;
  border: 1px solid ${(props: IStyledProps) => props.theme.colors.brandThemeDark700};
  margin-bottom: 1rem;
  border-radius: 3px;
`;

export const GroupQuestionNo = styled.div`
  background-color: ${(props: IStyledProps) => props.theme.colors.brandThemeDark700};
  width: 2rem;
  padding: 0.5rem;
  font-size: 1.15rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const GroupQuestionLabel = styled.span`
  padding: 0.5rem 0 0.5rem 1rem;
  width: calc(100% - 2.5rem - 130px); /* no padding + width and radio button width */
  font-size: 1rem;
  line-height: 1.5rem;
  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.md}) {
    font-size: 1.125rem;
    line-height: 1.69rem;
  }

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.xl}) {
    font-size: 1.38rem;
    line-height: 2rem;
  }
`;
