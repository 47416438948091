import React from 'react';
import { format } from 'date-fns';
import { ReactComponent as PrintIcon } from '@movember/mo-gel/assets/icons/baseline-print-24px.svg';

import {
  StyledGroupedResultsContainer,
  StyledGroupedResults,
  StyledMetadataCard,
  StyledHeading,
  StyledText,
  StyledQuestionnaireSummary,
  StyledDomain,
  StyledQuestionCard,
  StyledQuestionNumber,
  StyledResponse,
  StyledResultsContainer,
  StyledQuestionText,
  StyledQuestionsContainer,
  StyledDateHeading,
  StyledDateText,
  StyledHorizontalRule,
  StyledLink,
  StyledDomainHeading,
  StyledMetaContainer,
  StyledBotherContainer,
  StyledBotherCard,
  StyledBotherAnswerText,
  StyledBotherHeading
} from './groupedResults.styled';
import { Logo } from '../logo/logo';
import { IResponse } from '../../models/questionnaireResponse';
import { IQuestion } from '../../models/questionaire';

interface IBother {
  title: string;
  description: string;
  linkId: string;
}

// Hard coding these values here for the time being
// TODO: retrive this data from the backend
const botherAreas: IBother[] = [
  {
    title: 'Urinary Bother',
    description: 'You reported that urinary bother as',
    linkId: '9'
  },
  {
    title: 'Bowel Bother',
    description: 'You reported that bowel bother as',
    linkId: '15'
  },
  {
    title: 'Sexual Bother',
    description: 'You reported that sexual bother as',
    linkId: '21'
  }
];

const mapResponses = (questions: IQuestion[], responses: any): { [key: string]: IResponse[] } => {
  const report: { [key: string]: IResponse[] } = {};

  responses.forEach((response: any) => {
    if (!report[response.groupTitle]) {
      report[response.groupTitle] = [];
    }

    const linkId = parseInt(response.linkId, 10);

    report[response.groupTitle].push({
      linkId: linkId.toString(),
      text: response.text,
      answer: response.answer
    });
  });

  return report;
};

export const GroupedResults = (props: any) => {
  const {
    questions,
    answers: { item, authored }
  } = props;
  const report = mapResponses(questions, item);
  // TODO: update date to dpTimeline "completed" date
  const formattedDate = format(new Date(authored), "dd '—' MMMM '—' yyyy");

  const getAnswerByLinkId = (linkId: string) => {
    const result = item.find((answer: IResponse) => answer.linkId === linkId);
    return result ? result.answer[0].valueCoding.code : '-';
  };

  const handlePrint = () => window.print();

  return (
    <StyledResultsContainer>
      <Logo lighttheme="true" />
      <StyledGroupedResultsContainer>
        <StyledMetaContainer>
          <StyledQuestionnaireSummary>
            <StyledHeading level="h4">Questionnaire Summary</StyledHeading>
            <StyledText tag="p" marginBottom="0">
              Here is a summary of your responses to the questionnaire as answered by you. You can take this report to
              your doctor on your upcoming consultation, who will be able to understand your life after Prostate Cancer
              treatment better and suggest next steps accordingly.
            </StyledText>
          </StyledQuestionnaireSummary>
          <StyledMetadataCard inset="small">
            <StyledDateHeading tag="p" level="small" marginBottom="0.25rem">
              DATE QUESTIONNAIRE COMPLETED
            </StyledDateHeading>
            <StyledDateText tag="p" fontWeight="bold" marginBottom="1rem">
              {formattedDate}
            </StyledDateText>
            <StyledHorizontalRule />
            <StyledLink tertiary onClick={handlePrint}>
              <PrintIcon />
              Print Report
            </StyledLink>
          </StyledMetadataCard>
        </StyledMetaContainer>
        <StyledBotherContainer>
          {botherAreas.map((bother: IBother, index: number) => (
            <StyledBotherCard key={index}>
              <StyledBotherHeading level="h5">{bother.title}</StyledBotherHeading>
              <StyledText>{bother.description}</StyledText>
              <StyledBotherAnswerText>{getAnswerByLinkId(bother.linkId)}</StyledBotherAnswerText>
            </StyledBotherCard>
          ))}
        </StyledBotherContainer>
        <StyledGroupedResults>
          {Object.keys(report).map((key: string, index: number) => (
            <StyledDomain key={index}>
              <StyledDomainHeading level="h5">{key}</StyledDomainHeading>
              <StyledQuestionsContainer>
                {report[key].map((question: any, QuestionIndex: number) => (
                  <StyledQuestionCard key={QuestionIndex}>
                    <StyledQuestionNumber>{`Q${question.linkId}`}</StyledQuestionNumber>
                    <StyledResponse>
                      <StyledQuestionText marginBottom="0.9rem">{`${question.text}`}</StyledQuestionText>

                      {question.answer.map((answer: any, answerIndex: number) => (
                        <StyledText key={answerIndex} fontWeight="bold" marginTop={answerIndex > 0 ? '1rem' : '0'}>
                          {`${answer.valueCoding.code}`}
                        </StyledText>
                      ))}
                    </StyledResponse>
                  </StyledQuestionCard>
                ))}
              </StyledQuestionsContainer>
            </StyledDomain>
          ))}
        </StyledGroupedResults>
      </StyledGroupedResultsContainer>
    </StyledResultsContainer>
  );
};
