import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Heading } from '@movember/mo-gel';
import { StyledModal, StyledText } from './thankYouModal.styled';

export interface ThankYouModalProps {
  isOpen: boolean;
}

function ThankYouModal(props: ThankYouModalProps & RouteComponentProps) {
  const { isOpen } = props;

  return (
    <StyledModal isOpen={isOpen}>
      <Heading level="h2">We’ve received your responses</Heading>
      <StyledText>
        Thank you for completing the Quality of Life Survey.
        <br />
        We truly appreciate your time and honesty.
      </StyledText>
    </StyledModal>
  );
}

const tm = withRouter(ThankYouModal);

export { tm as ThankYouModal };
