import styled from 'styled-components';
import { Text, Button } from '@movember/mo-gel';
import { IStyledProps } from '../../models/StyledProps';

export const StyledContainer = styled.div`
  margin-top: 4.5rem;
  margin-left: 1.25rem;
  margin-right: 1.25rem;

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.xs}) {
    margin-left: 1.75rem;
  }
  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.sm}) {
    margin-left: 7rem;
    margin-top: 5.5rem;
  }
  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.md}) {
    margin-top: 5.75rem;
  }
  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.lg}) {
    margin-left: 8rem;
    margin-top: 5.95rem;
  }
  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.xl}) {
    margin-left: 11rem;
    margin-top: 7rem;
  }
`;

export const StyledGhostLinkText = styled(Text)`
  font-size: 1rem;

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.sm}) {
    font-size: 1.25rem;
  }
  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.xl}) {
    font-size: 1.5rem;
  }
`;

export const StyledSubmitButton = styled(Button)`
  align-self: center;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 2rem;
  line-height: 1.125rem;
  width: 100%;

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.xs}) {
    width: auto;
  }
  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.md}) {
    margin-top: 2.25rem;
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.xl}) {
    margin-top: 3rem;
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
`;

export const StyledPromptContainer = styled.div`
  width: 254px;
  margin-top: 10rem;
  padding-bottom: 3rem;

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.sm}) {
    width: 100%;
    padding-bottom: 1rem;
  }
`;

export const StyledHeaderText = styled(Text)`
  font-size: 1.35rem;
  display: flex;
  align-items: flex-start;
  padding: 0;
  font-weight: bold;
  color: ${(props: IStyledProps) => props.theme.colors.primaryFont};
  line-height: 1.7rem;
  padding-bottom: 1rem;

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.lg}) {
    font-size: 1.5rem;
    line-height: 1.9rem;
  }
  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.xl}) {
    font-size: 1.75rem;
    line-height: 2.25rem;
  }
`;

export const StyledText = styled(Text)`
  display: flex;
  align-items: flex-start;
  padding: 0;
  color: ${(props: IStyledProps) => props.theme.colors.primaryFont};
  line-height: 1.7rem;
`;
