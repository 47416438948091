import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { HEADER_TOKEN_KEY } from '../../constants';

export const Redir = (): any => <Redirect to="/auth" />;

export const PrivateRoute = (props: any) => {
  const { render } = props;

  return (sessionStorage.getItem(HEADER_TOKEN_KEY) && <Route {...props} render={render} />) || <Redir />; // eslint-disable-line react/jsx-props-no-spreading
};
