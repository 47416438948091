import { DefaultTheme } from 'styled-components';

const colors = {
  white: '#ffffff',
  black: '#000000',
  brandThemeDark900: '#263645',
  brandThemeDark700: '#385066',
  brandThemeDark500: '#567089',
  brandThemeLight500: '#d7d6cb',
  brandThemeLight300: '#e2ded1',
  brandThemeLight100: '#f1efe9',
  actionPrimary700: '#ba7700',
  actionPrimary500: '#d38700',
  actionPrimary300: '#ffa300',
  primaryShadow: 'rgba(12, 30, 44, 0.3)',
  primaryFont: '#fff',
  accentFont: '#4ba1e9',

  error500: '#bf0000',
  // new
  error300: '#e62323',

  // warning colors: orange and yellow
  warning500: '#f26800',
  // originally warning 1
  warning300: '#ff8200',

  // darkest/worse (500) to lightest/best (300)
  success500: '#76b33b',
  success300: '#8fd848'
};

const modal = {
  padding: `1rem`,
  background: {
    withLightBg: colors.white,
    withDarkBg: colors.brandThemeDark900
  },
  textColor: {
    withLightBg: colors.primaryFont,
    withDarkBg: colors.white
  },
  closeBgColor: 'rgba(255, 255, 255, 0.1)',
  closeHoverBgColor: colors.actionPrimary300
};

const button = {
  mainColor: {
    withLightBg: colors.actionPrimary300,
    withDarkBg: colors.actionPrimary300
  },
  textColor: {
    withLightBg: colors.actionPrimary300,
    withDarkBg: colors.actionPrimary300
  },
  hoverColor: colors.actionPrimary500,
  border: {
    radius: '8px'
  },
  padding: '.7em 2em',
  minWidth: '80px',
  fontSize: '1rem'
};

const breakpoints = {
  xxs: '320px',
  xs: '520px',
  sm: '768px',
  md: '1024px',
  lg: '1280px',
  xl: '1760px'
};

export const GenieTheme: DefaultTheme = {
  dark: true,
  name: 'True North Dark',
  colors,
  modal,
  button,
  breakpoints,
  containerMaxWidth: '1080px',
  defaultFontFamily: 'Poppins'
};
