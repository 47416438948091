import React from 'react';
import { Button } from '@movember/mo-gel';
import { StyledHeaderContainer, StyledSaveExitContainer } from '../../questionnaire.styled';

interface IHeaderContainerProps {
  setShowSavedModal: (val: boolean) => void;
}

export const HeaderContainer: React.FC<IHeaderContainerProps> = ({ setShowSavedModal }) => (
  <StyledHeaderContainer>
    <StyledSaveExitContainer>
      <Button
        secondary
        small
        width="auto"
        onClick={() => {
          setShowSavedModal(true);
        }}
      >
        Save and Exit
      </Button>
    </StyledSaveExitContainer>
  </StyledHeaderContainer>
);
