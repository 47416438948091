import styled from 'styled-components';
import { Text } from '@movember/mo-gel';
import { IStyledProps } from '../../models/StyledProps';

const saveExitHeight = '52px';

interface StyledFormProps extends IStyledProps {
  scrollBy: number;
  id: string;
}

interface StyledQuestionContainerProps extends IStyledProps {
  alignItems?: number;
  border?: string;
  isDisplay: boolean;
}

export const StyledQuestionnaire = styled.div`
  height: 100vh;
  width: 100vw;
  overflow: hidden;
`;

export const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  transform: translateY(${(props: StyledFormProps) => `calc(-${saveExitHeight} + ${props.scrollBy}vh)`});
  transition: transform 0.65s ease-in-out;
`;

export const StyledQuestionContainer = styled.div`
  width: 100vw;
  min-height: 100vh;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
  justify-content: flex-start;
  align-items: center;
  display: ${(props: StyledQuestionContainerProps) => (props.isDisplay ? 'flex' : 'none')};
  flex-direction: column;
  align-items: ${(props: StyledQuestionContainerProps) => props.alignItems};
  border: ${(props: StyledQuestionContainerProps) => props.border};
  box-sizing: border-box;
  padding: 0 1rem;

  @media screen and (min-width: ${(props: StyledQuestionContainerProps) => props.theme.breakpoints.xs}) {
    padding: 0 1.55rem;
  }

  @media screen and (min-width: ${(props: StyledQuestionContainerProps) => props.theme.breakpoints.sm}) {
    padding: 0 7.25rem 0 7.25rem;
    justify-content: center;
  }

  @media screen and (min-width: ${(props: StyledQuestionContainerProps) => props.theme.breakpoints.md}) {
    padding: 0 17.43rem 0 6.63rem;
  }

  @media screen and (min-width: ${(props: StyledQuestionContainerProps) => props.theme.breakpoints.lg}) {
    padding: 0 28.19rem 0 8.06rem;
  }

  @media screen and (min-width: ${(props: StyledQuestionContainerProps) => props.theme.breakpoints.xl}) {
    padding: 0 53.25rem 0 10.5rem;
  }
`;

export const StyledCardContainer = styled.div`
  min-height: 100vh;
`;

export const StyledLogoContainer = styled.div`
  position: fixed;
  top: 0;
  height: 3.75rem;
  background: ${(props: IStyledProps) => props.theme.colors.brandThemeDark900};
  z-index: 1;
  padding: 1rem 0 0 1rem;

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.sm}) {
    height: 4.5rem;
  }

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.md}) {
    height: 4.75rem;
    padding: 1.125rem 0 0 1.125rem;
  }

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.lg}) {
    height: 4.95rem;
    padding: 1.3125rem 0 0 1.3125rem;
  }

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.xl}) {
    height: 5.975rem;
    padding: 1.375rem 0 0 1.5625rem;
  }
`;

export const StyledSaveExitContainer = styled.div`
  float: right;
  margin-top: 1rem;
  margin-right: 1rem;
`;

export const StyledHeaderContainer = styled.div`
  z-index: 1;
  position: relative;
`;
