import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { IQuestionnaireFormattedResponse, IResponse } from '../../models/questionnaireResponse';
import { IQuestion } from '../../models/questionaire';
import { ErrorState } from '../../models/errorState';

import { HEADER_TOKEN_KEY, COMPLETE_STATUS } from '../../constants';
import { ThankYouModal } from '../modals/thankYouModal/thankYouModal';

interface AuthenticateProps {
  answers: IQuestionnaireFormattedResponse;
  questions: IQuestion[];
  errorState: ErrorState;
}

export default function Authenticate({ answers, questions, errorState }: AuthenticateProps) {
  if (sessionStorage.getItem(HEADER_TOKEN_KEY)) {
    // Check if there is any error
    if (errorState.occured === true) {
      return <Redirect to={{ pathname: '/error', state: { errorState } }} />;
    }

    // If the token is available in sessionStorage and there is no error, start the questionnaire
    if (answers.status === COMPLETE_STATUS) {
      // if the status is complete show Thankyou modal
      return <ThankYouModal isOpen />;
    }

    const item =
      answers.item &&
      answers.item.length &&
      answers.item.reduce((prev: IResponse, curr: IResponse) => (+curr.linkId > +prev.linkId ? curr : prev));
    const lastAnswered = item ? +item.linkId + 1 : 1;
    const nextQuestion = lastAnswered === 1 ? 1 : lastAnswered + 1;

    // if the last question of the questionnnaire is answered
    if (lastAnswered >= questions.length) {
      return <Redirect to="/question/confirm" />;
    }
    return <Redirect to={`/question/${nextQuestion}`} />;
  }
  // If no token we show an error
  return <Redirect to="/error" />;
}
