import styled, { css } from 'styled-components';
import { ReactComponent as genieLogo } from '../../assets/genie-logo.svg';

import { IStyledProps } from '../../models/StyledProps';

interface LogoProps extends IStyledProps {
  lighttheme?: boolean;
}

export const LogoStyle = (props: IStyledProps) => css`
  position: relative;
  margin-bottom: 0.6875rem;
  width: 7.06rem;
  height: 2.25rem;

  @media screen and (min-width: ${props.theme.breakpoints.sm}) {
    width: 9.81rem;
    height: 3.06rem;
  }
`;

export const StyledGenieLogo = styled(genieLogo)`
  ${(props: IStyledProps) => LogoStyle(props)}

  #Fill-2 {
    fill: ${(props: LogoProps) => (!props.theme.dark || props.lighttheme) && props.theme.colors.brandThemeDark900};
  }
`;
