/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
import { GhostLink, Button, ResponseItem } from '@movember/mo-gel';
import {
  StyledCard,
  StyledQuestionTitle,
  StyledQuestion,
  StyledArrow,
  StyledQuestionText,
  StyledButtonContainer,
  StyledGhostLinkText,
  StyledLinkSeperator,
  StyledDoneButtonContent,
  CheckboxWrapper,
  GroupQuestion,
  GroupQuestionNo,
  GroupQuestionLabel
} from './questionCard.styled';
import { IQuestion } from '../../models/questionaire';
import {
  SKIPPED,
  SKIPPED_CODE,
  QUESTION_INDEX_MAPPER,
  YES_CODE,
  YES_DISPLAY,
  NO_CODE,
  NO_DISPLAY
} from '../../constants';
import { IGroupQuestionAnswers, IResponse } from '../../models/questionnaireResponse';
import { QuestionCheckBox } from '../questionCheckBox/questionCheckBox';

interface QuestionCardProps {
  question: IQuestion;
  index: number;
  showNext: boolean;
  showPrevious: boolean;
  maxQuestions: number;
  selectedOptions: string[];
  isResponseDisabled: boolean;
  isGroupQuestions?: boolean;
  groupQuestionAnswers?: IGroupQuestionAnswers;
  onSelect: (selectedOptions: number[]) => void;
  onDeselect: () => void;
  onSkip: () => void;
  onPrevious: () => void;
  onNext: (response?: Array<IResponse>) => void;
}

export function QuestionCard({
  question,
  index,
  showNext,
  showPrevious,
  maxQuestions,
  selectedOptions,
  isResponseDisabled,
  isGroupQuestions,
  groupQuestionAnswers,
  onSelect,
  onDeselect,
  onSkip,
  onPrevious,
  onNext
}: QuestionCardProps) {
  const [selectedOptionIndexes, setSelectedOptionIndexes] = useState<number[]>([]);
  const [prevSelectedOptions, setPrevSelectedOptions] = useState<string[]>([]);
  const [isDirty, setIsDirty] = useState(false);
  const [groupQuestionResponse, setGroupQuestionResponse] = useState<IResponse[]>([]);

  useEffect(() => {
    // Set up group question's default response
    const response: Array<IResponse> = [];
    if (isGroupQuestions) {
      // if group question was answered(skiped as answered) before then don't show skip
      // if group question is the last question then don't show skip
      if (groupQuestionAnswers && groupQuestionAnswers.answers.length > 0) {
        setIsDirty(false);
      } else if (parseInt(question.linkId, 10) + 1 !== maxQuestions) {
        setIsDirty(true);
      } else {
        setIsDirty(true);
      }

      for (let i = 0; i < question.answerOption.length; i += 1) {
        if (groupQuestionAnswers) {
          response[i] = groupQuestionAnswers.answers[i];
        } else {
          response[i] = {
            linkId: (parseInt(question.linkId, 10) + i).toString(),
            text: question.answerOption[i].valueCoding.display,
            groupId: question.groupId,
            groupTitle: question.groupTitle,
            answer: [{ valueCoding: { code: SKIPPED_CODE, display: SKIPPED } }]
          };
        }
        setGroupQuestionResponse(response);
      }
    }
  }, []);

  useEffect(() => {
    // If there are already saved options passed in via props we update selectedOptionIndexes in the state
    // with the indexes of the selectedOptions
    if (
      !isGroupQuestions &&
      selectedOptions.length &&
      !selectedOptions.every((opt) => prevSelectedOptions.includes(opt))
    ) {
      const selectedIndexes: number[] = [];
      question.answerOption.forEach((opt, i) => {
        if (selectedOptions.includes(opt.valueCoding.code)) selectedIndexes.push(i);
      });
      setSelectedOptionIndexes(selectedIndexes);
      setPrevSelectedOptions(selectedOptions);
    }
  }, [prevSelectedOptions]);

  const deselectOption = () => {
    // No need to do anything here if it's a multiple choice question
    if (isGroupQuestions) return;

    onDeselect();
    setSelectedOptionIndexes([]);
    setIsDirty(true);
  };

  const handleOptionSelection = (i: number) => {
    // No need to do anything here if it's a multiple choice question
    if (isGroupQuestions) return;

    setSelectedOptionIndexes([i]);
    setIsDirty(false);
    setTimeout(() => {
      onSelect([i]);
    }, 0);
  };

  const handleSkip = () => {
    onSkip();
  };

  const handleNext = () => {
    // Pass the group question response as parameter if it's a group question question
    if (isGroupQuestions) {
      onNext(groupQuestionResponse);
    } else {
      onNext();
    }
  };

  const handlePrevious = () => {
    onPrevious();
  };

  const questionNumber = () => {
    const linkIdInt = parseInt(question.linkId, 10);
    const indexOffSet = 1; // linkIds start from 0 but we want to display them from 1
    // TO-DO: We might need to add this logic when we add a question after the comorb questions?
    // Each comorb question might have it's own linkid even though it's presented as 1 big question with subquestions
    let qInt = linkIdInt + indexOffSet;
    if (linkIdInt > 45) {
      if (question.groupId === 'domain 6 Follow Up') {
        // followUp epic26
        qInt = linkIdInt - 12;
      } else {
        // baseline
        qInt = linkIdInt - 11;
      }
    }
    const qString = qInt.toString();
    return qString;
  };

  const handleSelectionChange = (i: number, changedAnswer: string) => {
    const getResponse = [...groupQuestionResponse];
    if (getResponse[i]) {
      getResponse[i].answer[0].valueCoding.code = changedAnswer === YES_DISPLAY ? YES_CODE : NO_CODE;
      getResponse[i].answer[0].valueCoding.display = changedAnswer;
    }
    setGroupQuestionResponse(getResponse);
    setIsDirty(false);
  };

  const renderCheckboxes = (i: number) => {
    const labelOption = [YES_DISPLAY, NO_DISPLAY];
    const answeredValue = groupQuestionResponse[i] && groupQuestionResponse[i].answer[0].valueCoding;
    return (
      <CheckboxWrapper key={`checkbox-group-${i}`}>
        {labelOption.map((option: string) => (
          <QuestionCheckBox
            key={`checkbox${option}-${i}`}
            name={`checkbox${option}-${i}`}
            label={option}
            isChecked={answeredValue?.display === option}
            onChange={() => handleSelectionChange(i, option)}
          />
        ))}
      </CheckboxWrapper>
    );
  };

  return (
    <StyledCard id={`card${index}`} disabled={isResponseDisabled} inset="small" margin="2rem 0">
      <StyledQuestionTitle tag="p" marginBottom="1rem" level="small">
        {`Question `}
        <span>{questionNumber()}</span>
        {` of ${maxQuestions}`}
      </StyledQuestionTitle>
      <StyledQuestion>
        <StyledArrow />
        <StyledQuestionText marginBottom="1rem">{question.text}</StyledQuestionText>
      </StyledQuestion>

      <>
        {question.answerOption.map((option, responseIndex) =>
          isGroupQuestions ? (
            <GroupQuestion key={`group-question-${responseIndex}`}>
              <GroupQuestionNo>{QUESTION_INDEX_MAPPER[responseIndex]}</GroupQuestionNo>
              <GroupQuestionLabel>{option.valueCoding.display}</GroupQuestionLabel>
              {renderCheckboxes(responseIndex)}
            </GroupQuestion>
          ) : (
            <ResponseItem
              key={responseIndex}
              text={option.valueCoding.display}
              numbering={QUESTION_INDEX_MAPPER[responseIndex]}
              selected={selectedOptionIndexes.includes(responseIndex)}
              isAnimated={!isGroupQuestions}
              disabled={isResponseDisabled}
              selectOption={() => handleOptionSelection(responseIndex)}
              deselectOption={() => deselectOption()}
            />
          )
        )}
      </>
      <StyledButtonContainer>
        {showPrevious && (
          <GhostLink onClick={handlePrevious} icon="up">
            <StyledGhostLinkText>Previous</StyledGhostLinkText>
          </GhostLink>
        )}
        {showPrevious && <StyledLinkSeperator />}
        {(((showNext && selectedOptions.length > 0 && !selectedOptions.includes(SKIPPED)) ||
          isGroupQuestions ||
          selectedOptionIndexes.length) &&
          !isDirty && (
            <GhostLink onClick={handleNext} icon="down">
              <StyledGhostLinkText>Next</StyledGhostLinkText>
            </GhostLink>
          )) || (
          <GhostLink onClick={handleSkip} icon="down">
            <StyledGhostLinkText>Skip</StyledGhostLinkText>
          </GhostLink>
        )}
        {isGroupQuestions && !isDirty && (
          <Button
            primary
            small
            loading={false}
            disabled={false}
            width="5.4rem"
            onClick={handleNext}
            margin="0 0 0 auto"
          >
            <StyledDoneButtonContent>Okay / Apply</StyledDoneButtonContent>
          </Button>
        )}
      </StyledButtonContainer>
    </StyledCard>
  );
}

QuestionCard.defaultProps = {
  isGroupQuestions: false
};
