import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Heading } from '@movember/mo-gel';
import { StyledModal, StyledText } from './submitErrorModal.styled';

export interface SubmitErrorModalProps {
  isFailed: boolean;
}

function SubmitErrorModal(props: SubmitErrorModalProps & RouteComponentProps) {
  const { isFailed } = props;

  return (
    <StyledModal isOpen={isFailed}>
      <Heading level="h4">
        Oops!
        <br />
        Something went wrong while submitting
        <br />
        your survey.
      </Heading>
      <StyledText>
        Don&apos;t worry, your responses are saved - but you&apos;ll need to
        <br />
        submit the survey again. Open the survey link in your email
        <br />
        and click on the &apos;submit&apos; button once more.
      </StyledText>
      <StyledText>
        If you require assistance, please contact us using the
        <br />
        telephone number in your email.
      </StyledText>
    </StyledModal>
  );
}

const tm = withRouter(SubmitErrorModal);

export { tm as SubmitErrorModal };
