import styled from 'styled-components';
import { Card, Heading, Text, Link } from '@movember/mo-gel';
import { IStyledProps } from '../../models/StyledProps';

export const StyledResultsContainer = styled.div`
  padding: 1rem 1rem;
  background: ${(props: IStyledProps) => props.theme.colors.white};

  * {
    color: ${(props: IStyledProps) => props.theme.colors.brandThemeDark900};
  }
`;

export const StyledGroupedResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  border-top: 1px solid ${(props: IStyledProps) => props.theme.colors.brandThemeLight300};
`;

export const StyledMetaContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  padding-top: 1.625rem;
  margin-bottom: 4rem;
  width: 100%;

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.sm}) {
    padding: 1.8125rem 0rem 1rem 0rem;
    width: 534px;
  }

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.md}) {
    padding: 1.8125rem 2.1875rem 0rem 2.1875rem;
    width: 100%;
    max-width: 914px;
    flex-direction: row;
  }

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.lg}) {
    padding: 3.75rem 0rem 0rem 0rem;
    width: 100%;
    max-width: 1015px;
  }
`;

export const StyledGroupedResults = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  position: relative;

  /* Add top margin for the all domains except the first one */
  & > div:not(:nth-child(1)) {
    margin-top: 5rem;
  }

  /* Remove border for the last domain */
  & > div:last-child {
    border: none;
  }

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.sm}) {
    padding: 0rem 0rem 1rem 0rem;
    width: 534px;
  }

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.md}) {
    padding: 0rem 2.25rem 1rem 0.625rem;
    width: 100%;
    max-width: 914px;

    & > div:not(:nth-child(1)) {
      margin-top: 5.625rem;
    }
  }

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.lg}) {
    padding: 0 0rem 1rem 0rem;
    width: 100%;
    max-width: 1022px;
  }
`;

export const StyledMetadataCard = styled(Card)`
  width: 100%;
  background: ${(props: IStyledProps) => props.theme.colors.brandThemeLight100};
  border: none;
  margin-bottom: 2.6875rem;

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.md}) {
    margin-top: 3.25rem;
    margin-bottom: 0;
    width: 449px;
  }
`;

export const StyledDateHeading = styled(Text)`
  letter-spacing: 2px;
  font-size: 0.86875rem;

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.md}) {
    font-size: 0.9375rem;
  }

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.lg}) {
    font-size: 1.04375rem;
  }
`;

export const StyledDateText = styled(Text)`
  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.md}) {
    font-size: 1.125rem;
  }

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.lg}) {
    font-size: 1.25rem;
  }
`;

export const StyledHorizontalRule = styled.hr`
  border: solid 1px ${(props: IStyledProps) => props.theme.colors.brandThemeLight500};
  margin-bottom: 1rem;
`;

export const StyledLink = styled(Link)`
  display: flex;
  padding-bottom: 0;

  svg {
    fill: ${(props: IStyledProps) => props.theme.colors.brandThemeDark900};
    margin-right: 0.5rem;
  }

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.md}) {
    font-size: 1.125rem;
  }

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.lg}) {
    font-size: 1.25rem;
  }
`;

export const StyledQuestionnaireSummary = styled.div`
  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.md}) {
    width: 500px;
    margin: 0 4.125rem 0 0rem;
  }
`;

export const StyledDomain = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${(props: IStyledProps) => props.theme.colors.brandThemeLight300};

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.xs}) {
    padding: 0rem 1rem 0rem 0.5rem;
  }

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.sm}) {
    padding: unset;
  }

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.md}) {
    order: 2;
    padding: 0 0.5rem 0 0;
  }

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.lg}) {
    padding: 0;
  }
`;

export const StyledDomainHeading = styled(Heading)`
  margin-bottom: 1.25rem;

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.md}) {
    margin-bottom: 1.625rem;
  }
`;

export const StyledQuestionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.md}) {
    width: 100%;
    justify-content: space-between;
  }
`;

export const StyledQuestionCard = styled.div`
  display: flex;
  margin-bottom: 3.75rem;

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.lg}) {
    margin-right: 10rem;
  }
`;

export const StyledQuestionNumber = styled(Text)`
  color: ${(props: IStyledProps) => props.theme.colors.actionPrimary300};
  margin-right: 1.1875rem;
  font-size: 1.15rem;
  font-weight: bold;
  line-height: 1.38rem;

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.xs}) {
    margin-right: 1.6875rem;
  }

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.sm}) {
    margin-right: 1.5rem;
  }
`;

export const StyledQuestionText = styled(Text)`
  width: 279px;

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.xs}) {
    width: 420px;
  }

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.sm}) {
    width: 481px;
  }

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.md}) {
    width: 281px;
    font-size: 1.125rem;
  }
`;

export const StyledResponse = styled.div`
  display: flex;
  flex-direction: column;
  width: 279px;

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.xs}) {
    width: 420px;
  }

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.sm}) {
    width: 481px;
  }

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.md}) {
    width: 281px;
  }
`;

export const StyledHeading = styled(Heading)`
  font-size: 1.65rem;

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.sm}) {
    font-size: 1.8375rem;
  }
`;

export const StyledText = styled(Text)`
  line-height: 1.5;

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.sm}) {
    font-size: 1.0625rem;
  }

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.md}) {
    font-size: 1.125rem;
  }

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.lg}) {
    font-size: 1.25rem;
  }
`;

export const StyledBotherContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 4rem;

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.sm}) {
    width: 534px;
  }

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.md}) {
    padding: 0 2.1875rem 0 2.1875rem;
    width: 100%;
    max-width: 914px;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.lg}) {
    padding: 0;
    width: 100%;
    max-width: 1015px;
  }
`;

export const StyledBotherCard = styled(Card)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1.5rem;
  margin-bottom: 2rem;
  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.md}) {
    max-width: 30%;
    margin-bottom: 0;
  }
`;

export const StyledBotherHeading = styled(Heading)`
  font-size: 1.5rem;

  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.lg}) {
    font-size: 1.6rem;
  }
`;

export const StyledBotherAnswerText = styled(Text)`
  color: ${(props: IStyledProps) => props.theme.colors.actionPrimary300};
  font-weight: bold;
  font-size: 1.2rem;
  margin-top: 1rem;
  @media screen and (min-width: ${(props: IStyledProps) => props.theme.breakpoints.md}) {
    font-size: 1.4rem;
  }
`;
