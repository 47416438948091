import * as axios from 'axios';
import { compressSync, strToU8, strFromU8 } from 'fflate';
import {
  COMORBIDITY_TITLE,
  HEADER_TOKEN_KEY,
  NO_OF_CONDITIONAL_QUESTIONS,
  PARENT_NO,
  PARENT_YES,
  SESSION_KEY,
  SKIPPED,
  SKIPPED_CODE
} from '../../constants';
import { IQuestion } from '../../models/questionaire';
import { IAnswer, IAnswers, IQuestionnaireResponse, IResponse } from '../../models/questionnaireResponse';

export const calculateCurrentIndex = (answers: IAnswers) => {
  let isComorbiditiesAnswered = 0;
  return (
    answers.item.filter((item) => {
      if (item.groupTitle === COMORBIDITY_TITLE) isComorbiditiesAnswered = 1;
      return item.groupTitle !== COMORBIDITY_TITLE;
    }).length +
    1 +
    isComorbiditiesAnswered
  );
};

export const getSelectedOptions = (selectedOptions: IQuestionnaireResponse, id: string): string[] => {
  const answerItem = selectedOptions.item.find((item) => item && item.linkId === id);
  return answerItem ? answerItem.answer.map((ans) => ans.valueCoding.code) : [];
};

export const setIndexNext = (
  currentIndex: number,
  setCurrentIndex: (value: React.SetStateAction<number>) => void,
  setIsResponseDisabled: (value: React.SetStateAction<boolean>) => void,
  interval: number = 0
) => {
  setTimeout(() => {
    setCurrentIndex(currentIndex + 1);
  }, interval);
  setTimeout(() => {
    setIsResponseDisabled(false);
  }, interval + 1000);
};

export const setIndexPrevious = (
  currentIndex: number,
  setCurrentIndex: (value: React.SetStateAction<number>) => void,
  setIsResponseDisabled: (value: React.SetStateAction<boolean>) => void,

  interval: number = 0
) => {
  setTimeout(() => {
    setCurrentIndex(currentIndex - 1);
  }, interval);
  setTimeout(() => {
    setIsResponseDisabled(false);
  }, interval + 1000);
};

/**
 * Submit the response to the API and proceed to the next question
 * or final submission page if it's the last question
 */
export const saveAndProceedToNextStep = (
  history: any,
  currentIndex: number,
  setCurrentIndex: (value: React.SetStateAction<number>) => void,
  setIsResponseDisabled: (value: React.SetStateAction<boolean>) => void,
  questions: IQuestion[],
  question: IQuestion,
  parentValue?: string
) => {
  setIsResponseDisabled(true);
  const childCount = (question.children && question.children.length) || 0;
  const isConditional = childCount > 0 || false;

  if (currentIndex < questions.length) {
    setIndexNext(currentIndex, setCurrentIndex, setIsResponseDisabled, 500);
    history.push(`/question/${currentIndex + 1}`);

    // TO-DO: We don't have conditional questions anymore. Can we delete?
    // if (isConditional && parentValue === PARENT_YES) {
    //   // if conditional parent and selected option is "Yes"
    //   history.push(`/question/${currentIndex + 1}`);
    // } else if (isConditional && parentValue === PARENT_NO) {
    //   // if conditional parent and selected option is "No"
    //   if (currentIndex + NO_OF_CONDITIONAL_QUESTIONS === questions.length) {
    //     // if it is the last question in the questionnaire
    //     history.replace('/question/confirm');
    //   } else {
    //     history.push(`/question/${currentIndex + NO_OF_CONDITIONAL_QUESTIONS + 1}`);
    //   }
    // } else if (questions[+question.linkId + 1] && questions[+question.linkId + 1].enableWhen) {
    //   // if the question is a child, then push child to browser history
    //   history.push(`/question/${currentIndex + 1}`);
    // } else if (+question.linkId + 1 < questions.length) {
    //   // else just move to the next parent question
    // }
  } else {
    handleFinalQuestion(history, currentIndex, setCurrentIndex, setIsResponseDisabled);
  }
};

export const handleSkip = (
  history: any,
  skippingQuestion: IQuestion,
  questions: IQuestion[],
  currentIndex: number,
  setCurrentIndex: (value: React.SetStateAction<number>) => void,
  selectedOptions: IQuestionnaireResponse,
  setSelectedOptions: (value: React.SetStateAction<IQuestionnaireResponse>) => void,
  setIsResponseDisabled: (value: React.SetStateAction<boolean>) => void
) => {
  const { text, groupId, groupTitle } = skippingQuestion;
  const question: IQuestion = questions[currentIndex - 1];

  setIndexNext(currentIndex, setCurrentIndex, setIsResponseDisabled);

  // Handle skipping multiple choice question
  if (question.repeats) {
    let items = selectedOptions.item;
    items = items.filter((item) => item.groupTitle !== COMORBIDITY_TITLE);
    for (let i = 0; i < question.answerOption.length; i += 1) {
      items.push({
        linkId: (parseInt(question.linkId, 10) + i).toString(),
        text: question.answerOption[i].valueCoding.display,
        groupId: question.groupId,
        groupTitle: question.groupTitle,
        answer: [{ valueCoding: { code: SKIPPED_CODE, display: SKIPPED } }]
      });
    }
    setSelectedOptions({
      ...selectedOptions,
      item: [...items]
    });
  } else {
    // Handle skipping normal question
    setSelectedOptions({
      ...selectedOptions,
      item: pushNewItem(selectedOptions, question, {
        text,
        groupId,
        groupTitle,
        answer: [
          {
            valueCoding: {
              code: SKIPPED_CODE,
              display: SKIPPED
            }
          }
        ]
      })
    });
  }

  saveAndProceedToNextStep(history, currentIndex, setCurrentIndex, setIsResponseDisabled, questions, question, SKIPPED);
};

export const pushNewItem = (
  selectedOptions: IQuestionnaireResponse,
  question: IQuestion,
  {
    text,
    answer,
    groupId,
    groupTitle
  }: {
    text: string;
    answer: IAnswer[];
    groupId?: string;
    groupTitle?: string;
  }
) => {
  const linkId = parseInt(question.linkId.toString(), 10);

  let newItem = selectedOptions.item;
  newItem = newItem.filter((value) => +value.linkId !== linkId);

  newItem.push({
    linkId: `${linkId}`,
    text,
    groupId,
    groupTitle,
    answer
  });
  return newItem;
};

export const handleFinalQuestion = (
  history: any,
  currentIndex: number,
  setCurrentIndex: (value: React.SetStateAction<number>) => void,
  setIsResponseDisabled: (value: React.SetStateAction<boolean>) => void
) => {
  setIndexNext(currentIndex, setCurrentIndex, setIsResponseDisabled);
  history.push('/question/confirm');
};

export const handlePrevious = (
  history: any,
  selectedOptions: IQuestionnaireResponse,
  currentIndex: number,
  setCurrentIndex: (value: React.SetStateAction<number>) => void,
  setIsResponseDisabled: (value: React.SetStateAction<boolean>) => void
) => {
  setIsResponseDisabled(true);
  setIndexPrevious(currentIndex, setCurrentIndex, setIsResponseDisabled);

  // sort by link id in ascending order
  selectedOptions.item.sort((a, b) => (+a.linkId > +b.linkId ? 1 : -1));

  history.push(`/question/${currentIndex - 1}`);
};

export const handleNext = (
  history: any,
  questions: IQuestion[],
  selectedOptions: IQuestionnaireResponse,
  setSelectedOptions: (value: React.SetStateAction<IQuestionnaireResponse>) => void,
  currentIndex: number,
  setCurrentIndex: (value: React.SetStateAction<number>) => void,
  setIsResponseDisabled: (value: React.SetStateAction<boolean>) => void,
  question: IQuestion,
  response: undefined | Array<IResponse>
) => {
  // Handle multiple choices question, update and upload the response to S3
  if (question.repeats && response) {
    let items = selectedOptions.item;
    items = items.filter((item) => item.groupTitle !== COMORBIDITY_TITLE);
    items.sort((a, b) => (+a.linkId > +b.linkId ? 1 : -1));
    items.push(...response);
    setSelectedOptions({
      ...selectedOptions,
      item: [...items]
    });
  }
  setIsResponseDisabled(true);
  setIndexNext(currentIndex, setCurrentIndex, setIsResponseDisabled);

  if (+question.linkId + 1 < questions.length) {
    history.push(`/question/${currentIndex + 1}`);
  }
  // else handle final question
  else {
    handleFinalQuestion(history, currentIndex, setCurrentIndex, setIsResponseDisabled);
  }
};

/**
 * Remove any child answers
 * when User selects "No" in the conditional Parent question
 */
export const removeChildAnswers = (
  selectedOptions: IQuestionnaireResponse,
  setSelectedOptions: (value: React.SetStateAction<IQuestionnaireResponse>) => void,
  question: IQuestion,
  parentValue: string | undefined
) => {
  const items = selectedOptions.item;
  items.sort((a, b) => (+a.linkId > +b.linkId ? 1 : -1));
  if (question.children && question.children.length > 0 && (parentValue === PARENT_NO || !parentValue)) {
    question.children.forEach((childItem) => {
      const answerItem = items.find((item) => item.linkId === (+childItem + 1).toString()) as IResponse;
      if (answerItem) items.splice(items.indexOf(answerItem), 1);
    });

    setSelectedOptions({
      ...selectedOptions,
      item: [...items]
    });
  }
};

export const handleSelection = (
  history: any,
  questions: IQuestion[],
  currentIndex: number,
  setCurrentIndex: (value: React.SetStateAction<number>) => void,
  selectedOptions: IQuestionnaireResponse,
  setSelectedOptions: (value: React.SetStateAction<IQuestionnaireResponse>) => void,
  setIsResponseDisabled: (value: React.SetStateAction<boolean>) => void,
  question: IQuestion,
  selectedIndexes: number[]
) => {
  const { text, answerOption, groupId, groupTitle } = question;
  const newOptions = { ...selectedOptions };

  // We make sure that no child answers are saved when a user changes their answer from "Yes" to "No"
  // after having answered some/all of the conditional questions previously
  if (answerOption[selectedIndexes[0]].valueCoding.code === PARENT_NO)
    removeChildAnswers(
      selectedOptions,
      setSelectedOptions,
      question,
      answerOption[selectedIndexes[0]].valueCoding.code
    );

  // We disable the responses after one is selected before the animation delay
  setIsResponseDisabled(true);

  const answer: IAnswer[] = selectedIndexes.map((i) => ({
    valueCoding: {
      code: answerOption[i].valueCoding.code,
      display: answerOption[i].valueCoding.display
    }
  }));

  const answerItem = selectedOptions.item.find((item) => item.linkId === question.linkId) as IResponse;
  if (answerItem) {
    // re-answering question
    const newItemIndex = newOptions.item.indexOf(answerItem);
    newOptions.item[newItemIndex].answer = answer;
    setSelectedOptions(newOptions);
  } else {
    // new answer
    setSelectedOptions({
      ...selectedOptions,
      item: pushNewItem(selectedOptions, question, {
        text,
        groupId,
        groupTitle,
        answer
      })
    });
  }
  saveAndProceedToNextStep(
    history,
    currentIndex,
    setCurrentIndex,
    setIsResponseDisabled,
    questions,
    question,
    answerOption[selectedIndexes[0]].valueCoding.code
  );
};

export const handleDeselect = (
  history: any,
  questions: IQuestion[],
  currentIndex: number,
  selectedOptions: IQuestionnaireResponse,
  setSelectedOptions: (value: React.SetStateAction<IQuestionnaireResponse>) => void,
  question: IQuestion
) => {
  const newOptions = { ...selectedOptions };
  const answerItem = selectedOptions.item.find((item) => item.linkId === question.linkId) as IResponse;
  newOptions.item.splice(newOptions.item.indexOf(answerItem), 1);

  if (questions[currentIndex - 1].children && questions[currentIndex - 1].children!.length > 0) {
    removeChildAnswers(selectedOptions, setSelectedOptions, questions[currentIndex - 1], undefined);
  }

  setSelectedOptions(newOptions);
};

export const dismissWelcome = (setFirstLaunch: (value: React.SetStateAction<boolean>) => void) => {
  setFirstLaunch(false);
};

export const handleShowSkipModal = (
  setIsResponseDisabled: (value: React.SetStateAction<boolean>) => void,
  setShowSkipModal: (value: React.SetStateAction<boolean>) => void,
  setSkippingQuestion: (value: React.SetStateAction<IQuestion>) => void,
  questionToSkip: IQuestion
) => {
  // When showing the skip modal we also disable response selection
  setIsResponseDisabled(true);
  setShowSkipModal(true);
  setSkippingQuestion(questionToSkip);
};

export const handleHideSkipModal = (
  setIsResponseDisabled: (value: React.SetStateAction<boolean>) => void,
  setShowSkipModal: (value: React.SetStateAction<boolean>) => void
) => {
  setShowSkipModal(false);
  // Re-enable the response items after a delay to avoid accidental double click selections
  setTimeout(() => {
    setIsResponseDisabled(false);
  }, 1000);
};

export const backToQuestions = (
  history: any,
  currentIndex: number,
  setCurrentIndex: (value: React.SetStateAction<number>) => void,
  setIsResponseDisabled: (value: React.SetStateAction<boolean>) => void
) => {
  const previousQuestionId = currentIndex - 1;

  setIndexPrevious(currentIndex, setCurrentIndex, setIsResponseDisabled);

  history.push(`/question/${previousQuestionId}`);
};

export const submitResponse = async (selectedOptions: IQuestionnaireResponse) => {
  /// compress the body to avoid aws waf 8KB max body rule
  const buffer = strToU8(JSON.stringify({ answers: selectedOptions })); // stringify and convet to U8 typed array
  const compressedAnswers = window.btoa(strFromU8(compressSync(buffer), true)); // compress, convert to latin1 string and base64 encode
  const data = { compressedAnswers };
  const sessionId = sessionStorage.getItem(SESSION_KEY);

  return new Promise((resolve, reject) =>
    axios.default
      .put(`${process.env.REACT_APP_API_ENDPOINT}/answers`, data, {
        headers: {
          [HEADER_TOKEN_KEY]: sessionStorage.getItem(HEADER_TOKEN_KEY),
          [SESSION_KEY]: sessionId
        }
      })
      .then((response) => {
        if (response.status === 200) {
          return resolve(null);
        }
        // TODO: handle errors appropriately
        return reject(new Error(`ERROR: ${response.status} : ${response.statusText}`));
      })
      .catch((error) => reject(error))
  ).catch((error) => error);
};
