import styled, { createGlobalStyle } from 'styled-components';
import { IStyledProps } from './models/StyledProps';

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    padding: 0;
    margin: 0;
    font-family: ${(props: IStyledProps) => props.theme.defaultFontFamily}, sans-serif;
    background: ${(props) => props.theme.colors.brandThemeDark900};
  }
`;

export const StyledSpinnerContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;
