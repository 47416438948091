import React from 'react';
import { StyledMessage, StyledHeading, StyledText } from './authenticationError.styled';
import { ErrorState } from '../../models/errorState';

interface ErrorProps {
  errorState: ErrorState;
}
export default function Error({ errorState }: ErrorProps) {
  const generalErrorMessage = () => (
    <StyledMessage>
      <StyledHeading level="h4">Error 404 - Page Not Found</StyledHeading>
      <StyledText>
        Oops, looks like the page you’re looking for is not available.
        <br />
        Please check you have the correct link.
      </StyledText>
    </StyledMessage>
  );

  const expiredErrorMessage = () => (
    <StyledMessage>
      <StyledHeading level="h4">
        We’re sorry. <br />
        This link has now expired.
      </StyledHeading>
      <StyledText>Please call the contact number in your email if you require more information.</StyledText>
    </StyledMessage>
  );

  if (errorState.status === 400) {
    return expiredErrorMessage();
  }
  return generalErrorMessage();
}
